import { createSlice } from "@reduxjs/toolkit";

export const countryInfoSlice = createSlice({
    name: "Countryinfo",
    initialState: {
        country: "",
        enabledCountries: [],
        selectedEnableCountry: ""
    },

    reducers: {
        setEnabledCountries: (state, action) => {
            state.enabledCountries = action.payload;
        },
        setSelectedEnableCountry: (state, action) => {
            state.selectedEnableCountry = action.payload;
        },
    },
});

export const {
    setEnabledCountries,
    setSelectedEnableCountry
    
} = countryInfoSlice.actions;
export default countryInfoSlice.reducer;


