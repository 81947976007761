import React, { lazy, useState } from "react";
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import routes from "routes.js";
import { Container } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { useAlert } from "components/shared/AlertContext";
import { ALERT_TYPES } from "utils/Constants";
import jwtDecode from "jwt-decode";

const Navbar = lazy(() => import("components/shared/Navbars/Navbar.js"));
const Sidebar = lazy(() => import("components/shared/Sidebar/Sidebar.js"));
const CustomLoader = lazy(() => import("components/shared/CustomLoader"));

var ps;

const PayinLayout = (props) => {
  const [backgroundColor, setBackgroundColor] = React.useState("black");
  const [activeColor, setActiveColor] = React.useState("info");
  const [sidebarExpanded, setSidebarExpanded] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const showNotification = useAlert();
  const mainPanel = React.useRef();
  const location = useLocation();

  const { getAccessTokenSilently } = useAuth0();
  const aceessToken = getAccessTokenSilently();

  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.body.classList.toggle("perfect-scrollbar-on");
      }
    };
  }, [sidebarExpanded]);

  React.useEffect(() => {
    mainPanel.current.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;

    const pai = localStorage.getItem("pai");
    if (!pai || !pai.trim()) {
      const fetchData = async () => {
        try {
            const token = await getAccessTokenSilently();
            const decodedToken = jwtDecode(token);
            const enabledCountries = decodedToken.enabledCountries;
            const countriesArray = enabledCountries.split(',');
            if (countriesArray && countriesArray.length > 0) {
              const firstCountry = countriesArray[0];
              history.push("/payin/home/" + firstCountry);
            }
        } catch (error) {
          showNotification(
            "You must select a country to continue operation.",
            ALERT_TYPES.error
          );
        }
        
      };
      fetchData();
    }
  }, [location]);

  const handleActiveClick = (color) => {
    setActiveColor(color);
  };

  const handleBgClick = (color) => {
    setBackgroundColor(color);
  };

  const toggleSidebar = () => {
    setSidebarExpanded(!sidebarExpanded);
  };

  const onShowLoader = (show) => {
    setIsLoading(show);
  };

  return (
    <div className="wrapper">
      {isLoading && <CustomLoader className="loader-center-content" />}

      <Sidebar
        {...props}
        layoutName="payin"
        headerLogo="https://www.morepaymentevolution.com/src/img/logo.svg"
        routes={routes}
        bgColor={backgroundColor}
        activeColor={activeColor}
        isExpanded={sidebarExpanded}
      />
      <div
        className={`main-panel ${!sidebarExpanded && "main-panel-big"}`}
        ref={mainPanel}
      >
        <Navbar
          {...props}
          onToggleSideBar={toggleSidebar}
          isLoginView={false}
          onShowLayoutLoader={onShowLoader}
        />
        <Container fluid className="content">
          <React.Suspense
            fallback={<CustomLoader className="loader-center-content" />}
          >
            <Switch>
              {routes.map((prop, key) => {
                return prop.component ? (
                  <Route
                    exact
                    path={prop.layout + prop.path}
                    component={prop.component}
                    key={key}
                  />
                ) : (
                  prop.childrens?.map((childProp, childKey) => {
                    return (
                      <Route
                        exact
                        path={childProp.layout + childProp.path}
                        component={childProp.component}
                        key={childKey}
                      />
                    );
                  })
                );
              })}
            </Switch>
          </React.Suspense>
        </Container>
      </div>
    </div>
  );
};

export default PayinLayout;
