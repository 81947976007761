import { createSlice } from "@reduxjs/toolkit";

export const rateBaseInfoSlice = createSlice({
  name: "rateBaseInfo",
  initialState: {
    date: null,
    currencyId: null,
  },

  reducers: {
    setRateBaseInfo: (state, action) => {
      state.date = action.payload.date;
      state.currencyId = action.payload.currencyId;
    },
  },
});

export const { setRateBaseInfo, getRateBaseInfo } = rateBaseInfoSlice.actions;

export default rateBaseInfoSlice.reducer;

export const fetchTest = () => (dispatch) => {};
