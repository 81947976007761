import React from "react";
import { ALERT_TYPES } from "utils/Constants";
import NotificationAlert from "react-notification-alert";

const AlertContext = React.createContext();

export const AlertProvider = ({ children }) => {
  const notificationAlertRef = React.useRef(null);

  const showNotification = (message, type) => {
    notificationAlertRef.current.notificationAlert({
      place: "tr",
      message: message,
      type: type,
      autoDismiss: 5,
      icon:
        type == ALERT_TYPES.success
          ? "nc-icon nc-check-2"
          : "nc-icon nc-simple-remove",
    });
  };

  return (
    <AlertContext.Provider value={showNotification}>
      {children}
      <NotificationAlert ref={notificationAlertRef} />
    </AlertContext.Provider>
  );
};

export const useAlert = () => {
  const showNotification = React.useContext(AlertContext);

  return showNotification;
};
