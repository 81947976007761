import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import PayinLayout from "components/pages/Payin/PayinLayout";
import "./styles.scss";
import { lazy, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import CustomLoader from "components/shared/CustomLoader";
import React, { createContext, useRef, useState } from "react";
import { AlertProvider } from "components/shared/AlertContext";
import { useDispatch } from "react-redux";
import jwtDecode from "jwt-decode";
import { setAuthData } from "store/slices/authSlice";
import { resetAuthData } from "store/slices/authSlice";
import PasswordExpiredComponent from "components/shared/PasswordExpiredComponent";
import { Container } from "reactstrap";

const Navbar = lazy(() => import("components/shared/Navbars/Navbar.js"));

const App = () => {
  const [passwordExpired, setPasswordExpired] = useState(false);
  const [hasPermission, setHasPermission] = useState(false);

  const { isLoading, loginWithRedirect, user, isAuthenticated, error, getAccessTokenSilently } =
    useAuth0();

  const dispatch = useDispatch();

  const handleLoginAgain = async () => {
    try {
      await loginWithRedirect();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!passwordExpired) {
      (async function login() {
        if (!isLoading && !user) {
          if (error && error?.message === "Your password has expired.  Please reset it.") {
            setPasswordExpired(true);
          } else {
            await handleLoginAgain();
          }
        }
        await setRolesAndPermission();
      })();
    }
  }, [isLoading, user, isAuthenticated, error]);

  const loaderStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  const setRolesAndPermission = async () => {
    const token = await getAccessTokenSilently();
    if (token) {
      const decode = jwtDecode(token);
      dispatch(setAuthData(decode));
      if (
        decode.userRol.includes("BACKOFFICE_PAYIN_ACCESS") ||
        decode.userRol.includes("ADMIN_PAYIN")
      ) {
        setHasPermission(true);
      }
    } else {
      dispatch(resetAuthData());
    }
  };

  if (isLoading)
    return (
      <div style={loaderStyle}>
        <CustomLoader />
      </div>
    );
  if (passwordExpired) {
    return <PasswordExpiredComponent setModal={setPasswordExpired} modal={passwordExpired} />;
  }

  return (
    isAuthenticated &&
    (hasPermission ? (
      <BrowserRouter>
        <AlertProvider>
          <React.Suspense
            fallback={
              <div style={loaderStyle}>
                <CustomLoader />
              </div>
            }
          >
            <Switch>
              <Route path="/payin" render={(props) => <PayinLayout {...props} />} />
              <Redirect to="/payin" />
            </Switch>
          </React.Suspense>
        </AlertProvider>
      </BrowserRouter>
    ) : (
      <>
        <Navbar isLoginView={true} />
        <Container
          fluid
          className="screen-height d-flex align-items-center justify-content-center"
          style={{ height: "85vh" }}
        >
          <h5>You don't have access to this application.</h5>
        </Container>
      </>
    ))
  );
};

export default App;
