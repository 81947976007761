import { configureStore } from "@reduxjs/toolkit";
import countryInfo from "./slices/countryInfo";
import rateBaseInfo from "./slices/rateBaseInfo";
import transactionInfoSlice from "./slices/transactionInfo";
import authSlice from "./slices/authSlice";

export default configureStore({
  reducer: {
    countryInfo,
    rateBaseInfo,
    transactionInfoSlice,
    authSlice,
  },
});
