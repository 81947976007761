const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  referenceNumber: null,
  dateFrom: new Date().toISOString().split("T")[0],
  dateTo: new Date().toISOString().split("T")[0],
};

const transactionInfoSlice = createSlice({
  name: "transactionInfo",
  initialState,
  reducers: {
    setDateFromt: (state, action) => {
      state.dateFromt = action.payload;
    },
    setDateTo: (state, action) => {
      state.dateTo = action.payload;
    },
    setReferenceNumber: (state, action) => {
      state.referenceNumber = action.payload;
    },
    reset: () => initialState, // Resetear el estado al valor inicial
  },
});

export const { setStartDate, setEndDate, setReferenceNumber, reset } =
  transactionInfoSlice.actions;

export default transactionInfoSlice.reducer;
