import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userRoles: [],
  permissions: [],
  isAdmin: false,

  // FINANCE
  isCalculationListAccess: false,
  isCalculationEditorAccess: false,
  isCommissionListAccess: false,
  isCommissionEditorAccess: false,
  isRateBaseListAccess: false,
  isRateBaseEditorAccess: false,
  isRateLocalListAccess: false,
  isRateLocalEditorAccess: false,
  isTaxListAccess: false,
  isTaxEditorAccess: false,
  //COMMERCIAL
  isMerchantAggregatorListAccess: false,
  isMerchantAggregatorEditorAccess: false,
  // OPERATION
  isTransactionListAccess: false,
  //UTILS
  isUtilsMenuAccess: false,
  //USER
  isUserListAccess: false,
  isUserEditorAccess: false,
  //PAYMENT
  isPaymentListAccess: false,
  isPaymentEditorAccess: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthData: (state, action) => {
      const decode = action.payload;
      const { userRoles, permissions } = decode;

      state.userRoles = userRoles;
      state.permissions = permissions;

      state.isAdmin = permissions.includes("payinBackoffice_admin_access");

      // los demas access no se estan usando. Ver posible mejora para usarlos
      // state.isCalculationListAccess = permissions.includes("BACKOFFICE_PAYIN_CALCULATION_LIST");
      // state.isCalculationEditorAccess = permissions.includes("BACKOFFICE_PAYIN_CALCULATION_EDITOR");
      // state.isCommissionListAccess = permissions.includes("BACKOFFICE_PAYIN_COMMISSION_LIST");
      // state.isCommissionEditorAccess = permissions.includes("BACKOFFICE_PAYIN_COMMISSION_EDITOR");
      // state.isRateBaseListAccess = permissions.includes("BACKOFFICE_PAYIN_RATE_BASE_LIST");
      // state.isRateBaseEditorAccess = permissions.includes("BACKOFFICE_PAYIN_RATE_BASE_EDITOR");
      // state.isRateLocalListAccess = permissions.includes("BACKOFFICE_PAYIN_RATE_LOCAL_LIST");
      // state.isRateLocalEditorAccess = permissions.includes("BACKOFFICE_PAYIN_RATE_LOCAL_EDITOR");
      // state.isTaxListAccess = permissions.includes("BACKOFFICE_PAYIN_TAX_LIST");
      // state.isTaxEditorAccess = permissions.includes("BACKOFFICE_PAYIN_TAX_EDITOR");
      // state.isMerchantAggregatorListAccess = permissions.includes("BACKOFFICE_PAYIN_MERCHANT_AGGREGATOR_LIST");
      // state.isMerchantAggregatorEditorAccess = permissions.includes("BACKOFFICE_PAYIN_MERCHANT_AGGREGATOR_EDITOR");
      // state.isTransactionListAccess = permissions.includes("BACKOFFICE_PAYIN_TRANSACTION_LIST");
      // state.isUtilsMenuAccess = permissions.includes("BACKOFFICE_PAYIN_UTILS");
      // state.isUserListAccess = permissions.includes("BACKOFFICE_PAYIN_USER_LIST");
      // state.isUserEditorAccess = permissions.includes("BACKOFFICE_PAYIN_USER_EDITOR");
      // state.isPaymentListAccess = permissions.includes("BACKOFFICE_PAYIN_PAYMENT_LIST");
      // state.isPaymentEditorAccess = permissions.includes("BACKOFFICE_PAYIN_PAYMENT_EDITOR");
    },
    resetAuthData: (state) => {
      state = initialState;
    },
  },
});

export const { setAuthData, resetAuthData } = authSlice.actions;

export default authSlice.reducer;
