import { Oval } from "react-loader-spinner";

const CustomLoader = ({ className }) => {
  return (
    <div className={className}>
      <Oval
        height={125}
        width={125}
        color="#ea7c07"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#ea7c07"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    </div>
  );
};

export default CustomLoader;
